<template>
  <div class="nav-category">
    <transition name="fade" mode="out-in">
      <button-base
        v-show="prevButtonShow"
        class="nav-category__control nav-category__control-prev"
        type="secondary-3"
        size="xs"
        @click="clickAction('prev')"
      >
        <atomic-icon id="arrow-navigate-before" />
      </button-base>
    </transition>

    <transition name="fade" mode="out-in">
      <button-base
        v-show="nextButtonShow"
        class="nav-category__control nav-category__control-next"
        type="secondary-3"
        size="xs"
        @click="clickAction('next')"
      >
        <atomic-icon id="arrow-navigate-next" />
      </button-base>
    </transition>

    <div
      ref="scrollContainer"
      class="nav-category__container"
      :class="{ 'show-prev-overlay': prevOverlayShow, 'show-next-overlay': nextOverlayShow }"
      @scroll="scrollHandler"
    >
      <button-base
        ref="providersButton"
        class="nav-category__filter"
        type="secondary-3"
        @click="handleProvidersModalOpen"
      >
        <atomic-icon
          :id="
            getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.providersFilter.icon')
          "
        />
        {{
          getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.providersFilter.label')
        }}
      </button-base>

      <button-base
        class="nav-category__filter"
        type="secondary-3"
        @click="openModal('categories', { props: { categories: props.categories } })"
      >
        <atomic-icon
          :id="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.gamesFilter.icon')"
        />
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'categories.gamesFilter.label') }}
      </button-base>

      <client-only>
        <template v-if="isLoggedIn">
          <transition name="fade" mode="out-in">
            <card-category
              v-if="hasRecentlyGames"
              key="recently"
              type="transparent"
              :categoryData="recentlyCategory"
              accent
            />
          </transition>

          <transition name="fade" mode="out-in">
            <card-category
              v-if="favoriteGames.length"
              key="favorites"
              type="transparent"
              :categoryData="favoritesCategory"
              accent
            />
          </transition>
        </template>
      </client-only>

      <card-category
        v-for="category in props.categories"
        :key="category.id"
        type="transparent"
        :categoryData="category"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { ICollection } from '~/core/types';

  const props = defineProps<{
    categories: ICollection[];
  }>();

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const gamesStore = useGamesStore();
  const { hasRecentlyGames, favoriteGames } = storeToRefs(gamesStore);
  const { recentlyCategory, favoritesCategory } = gamesStore;
  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);
  const { getContent, openKeyboard } = useProjectMethods();
  const { openModal } = useModalStore();

  const scrollContainer = ref();
  const prevButtonShow = ref(false);
  const nextButtonShow = ref(false);
  const prevOverlayShow = ref(false);
  const nextOverlayShow = ref(false);

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;
    prevButtonShow.value = scrollLeft > 10;
    prevOverlayShow.value = scrollLeft !== 0;
    nextButtonShow.value = scrollLeft + offsetWidth + 10 < scrollWidth;
    nextOverlayShow.value = Math.abs(scrollWidth - scrollLeft - offsetWidth) > 1;
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  const providersButton = ref();
  const handleProvidersModalOpen = (): void => {
    openKeyboard(providersButton.value.$el);
    openModal('providers');
  };

  watch(
    () => props.categories,
    async () => {
      await nextTick();
      scrollHandler();
    }
  );

  onMounted(async () => {
    await nextTick();
    scrollHandler();

    if (isLoggedIn.value) {
      const { checkRecentlyGames } = useGamesStore();
      checkRecentlyGames();
    }
  });
</script>

<style src="~/assets/styles/components/nav/category.scss" lang="scss" />
